import React from "react";

export const Card = ({ url, image, title, body }) => {
  return (
    <article>
      <div className="relative max-w-sm min-h-500 flex flex-col bg-white rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <a href={url} target="_blank" rel="noreferrer">
          <img className="rounded-t-lg" src={image} alt="" />
        </a>
        <div className="p-5 flex flex-col items-center">
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>

          <p className="mb-3 font-normal text-left text-gray-700 dark:text-gray-400">
            {body}
          </p>

          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className="absolute bottom-4 inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Read more
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        </div>
      </div>
    </article>
  );
};
