export const Main = () => {
  return (
    <section
      id="main"
      className="h-screen flex justify-center items-center"
      style={{
        backgroundImage: "url('./assets/background6.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className=" py-8 px-4 mx-auto max-w-screen text-center lg:py-16">
        <h1 className="mb-4 text-4xl text-white font-extrabold tracking-tight leading-none  md:text-5xl lg:text-6xl dark:text-white">
          We invest in the world’s potential
        </h1>
        <p className="mb-8 text-lg font-normal text-white lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
          Here at WebSmith Creations we focus on markets where technology,
          innovation, and capital can unlock long-term value and drive economic
          growth.
        </p>
        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0">
          <a
            href="#projects"
            className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
          >
            Contact Us
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
          <a
            href="#about"
            className="inline-flex justify-center items-center py-3 px-5 sm:ms-4 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 bg-gray-100 hover:bg-gray-300 focus:ring-4 focus:ring-gray-100 dark:text-gray-700 dark:hover:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
            Learn more
          </a>
        </div>
      </div>
    </section>
  );
};
